import React from "react";

const RightOrien = () => {
  return (
    <div className="right-orientation">
      <div className="ra">
        <a href="mailto:harshilprajaptiofficial@gmail.com">harshilprajapatiofficial@gmail.com</a>
      </div>
    </div>
  );
};

export default RightOrien;
