import React from "react";

const Experience = () => {
  return (
    <section id="experience" className="experience">
      <h3 className="section-head">Where I've worked</h3>
      <div className="descjb">
        <ul className="listjb">
          <li>
            <button className="listjb-btn">
              <a
                href="https://www.buchanan.com/"
                className="cmp-btn"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <span>Buchanan Technologies</span>
              </a>
            </button>
          </li>
        </ul>
        <div className="jbdesc">
          <h4 className="jbhead">
            <span>Service Desk Analyst</span>
          </h4>
          <h5 className="period">
            <span className="j">October 2021 - Preset</span>
          </h5>
          <div>
            <ul className="jbli">
              <li>Handle incoming incidents and service requests through various channels, including phone calls, emails, and tickets in the IT service management system. Document all interactions and actions taken to ensure accurate and up-to-date incident records.</li>
              <li>Assist with the setup, configuration, and deployment of computer systems, peripherals, and software applications for new hires and existing employees. Ensure compliance with company policies and security standards during the provisioning process.</li>
              <li>Demonstrated expertise in Active Directory administration, efficiently managing user accounts, groups, and Group Policies. Implemented automated processes and optimized workflows, resulting in a 30% reduction in user access provisioning time.</li>
              <li>Utilize remote desktop tools to provide assistance to off-site employees, ensuring consistent service quality regardless of location.</li>
              <li>Provided comprehensive support for Microsoft Exchange and Office 365 environments, ensuring smooth email communication. Effectively resolved an average of 50 email delivery issues per month, guaranteeing reliable email services for end-users.</li>
              <li>Collaborate with the IT team to implement software and hardware updates, patches, and upgrades across the organization with 30,000 users. Conduct thorough testing to ensure compatibility and minimal disruptions to end-users.</li>
              <li>Recognized for consistently maintaining a high customer satisfaction rating of over 95% through positive feedback surveys.</li>
              <li>Successfully resolved more than 10 critical system outage incidents within 30 minutes, minimizing downtime and preventing potential financial losses.</li>
              <li>Implemented a new ticket categorization system, reducing response times and improving incident prioritization by 20%.</li>
              <li>Contribute to the development and maintenance of the organization's knowledge base, where documented 50 + solutions to common issues and practices to expedite future problem resolution.</li>
              <li>Achieved a 10% increase in customer conversion rate through proactive support and personalized interactions.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
