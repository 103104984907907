import React from "react";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import projects from "./projects.json";

const Project = () => {
  return (
    <section id="work" className="project">
      <h3 className="section-head">Some Things, I've Built</h3>
      <Carousel autoplay>
        {projects.map((project, index) => (
          <div key={index}>
            <div className="project-detail">
              <div className="project-1 prjrm">
                <h4 className="project-2 project-fea">Featured Project</h4>
                <h5 className="project-3 project-title">
                  <a
                    href={project.url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    {project.title}
                  </a>
                </h5>
                <div className="project-4 project-about">
                  <p>{project.description}</p>
                </div>
                <ul className="project-5 project-tool">
                  {project.tools.map((tool, index) => (
                    <li key={index}>{tool}</li>
                  ))}
                </ul>
                <div className="project-6 project-url">
                  <a
                    href={project.githubUrl}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    aria-label="Github Link"
                  >
                    <img
                      className="icon"
                      src={require("../icon/github.svg")}
                      alt=""
                    />
                  </a>
                  <a
                    href={project.url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    aria-label="External Link"
                  >
                    <img
                      className="icon"
                      src={require("../icon/link.svg")}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <a
                href={project.url}
                target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label="External Link"
                className="project-img project-8"
              >
                <div
                  style={{ position: "relative", overflow: "hidden" }}
                  className="project-img-div"
                >
                  <div style={{ width: "100%", paddingBottom: "62.2857%" }}></div>
                  <img
                    src={require(`../icon/${project.imageUrl}`)}
                    alt="Project"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center center",
                      opacity: "0",
                      transitionDelay: "500ms",
                    }}
                  />
                  <picture>
                    <source />
                    <img
                      src={require(`../icon/${project.imageUrl}`)}
                      alt={`Project ${index + 1}`}
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        maxWidth: "700px",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        opacity: "1",
                        objectPosition: "center center",
                        transition: "opacity 500ms ease 0s",
                      }}
                    />
                  </picture>
                </div>
              </a>
            </div>
          </div>
        ))}
      </Carousel>
    </section>
  );
};

export default Project;
